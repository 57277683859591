<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.personalInformation')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="4"
              class="d-flex justify-center align-center"
            >
              <ImageUpload
                :image.sync="uploadedImg"
                :avatar="myObj.avatar"
                :defaultImg="blankProfile"
              />
            </v-col>

            <v-col cols="12" lg="10" md="10" sm="8">
              <v-row>
                <v-col class="py-0" cols="12" lg="3" md="3" sm="4">
                  <v-text-field
                    style="color: black"
                    :label="$vuetify.lang.t('$vuetify.name')"
                    :rules="[rule.required]"
                    v-model="myObj.name"
                  />
                </v-col>

                <v-col class="py-0" cols="12" lg="3" md="3" sm="4">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.IDNo')"
                    v-model="myObj.id_pass"
                    :rules="[rule.required]"
                  />
                </v-col>

                <v-col class="py-0" cols="12" lg="3" md="3" sm="4">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.passport')"
                    v-model="myObj.passport"
                  />
                </v-col>

                <v-col class="py-0" cols="12" lg="3" md="3" sm="12">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.passportName')"
                    v-model="myObj.name_on_passport"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.nationality')"
                    v-model="myObj.nationality"
                  />
                </v-col>

                <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.mobile')"
                    v-model="myObj.mobile"
                    :rules="[rule.required]"
                  />
                </v-col>

                <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.address')"
                    v-model="myObj.address"
                    :rules="[rule.required]"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12" lg="3" md="4" sm="6">
                  <v-menu
                    ref="birthday"
                    v-model="birthday"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    offset-y
                    class="ma-0 pa-0"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                      class="ma-0 pa-0"
                    >
                      <v-text-field
                        append-icon="mdi-calendar"
                        v-model="myObj.birthday"
                        :label="$vuetify.lang.t('$vuetify.dateBirth')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[rule.required]"
                      />
                    </template>

                    <v-date-picker
                      ref="BdPicker"
                      v-model="myObj.birthday"
                      :max="new Date().toISOString().substr(0, 10)"
                      :show-current="false"
                      @input="date = false"
                      class="ma-0 pa-0"
                      @change="saveBd"
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12" lg="9" md="8" sm="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.note')"
                    v-model="myObj.note"
                    class="pa-0"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Personal Documents -->
          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-file-document</v-icon>
                {{ $vuetify.lang.t("$vuetify.personalDocx") }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12">
              <v-file-input
                v-model="files"
                placeholder="Upload your documents"
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                multiple
                accept="image/jpeg, image/png, application/pdf"
                color="deep-purple accent-4"
                counter
                :show-size="1000"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 4"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>

                  <span
                    v-else-if="index === 4"
                    class="overline grey--text text--darken-3 mx-2"
                  >
                    +{{ files.length - 2 }} File(s)
                  </span>
                </template>
              </v-file-input>
            </v-col>

            <v-col cols="12" class="pt-0" v-if="myObj.attach.length > 0">
              <span class="display-1 font-weight-bold">
                List of Documents
              </span>

              <v-sheet
                elevation="4"
                class="py-4"
                :height="myObj.attach.length < 1 ? '200px' : ''"
              >
                <span
                  class="d-flex align-center justify-center"
                  v-for="(item, key) in myObj.attach"
                  :key="key"
                >
                  <v-list-item
                    class="tile ml-4 mr-2 my-2"
                    target="_blank"
                    :href="dataUrl + item.md5"
                  >
                    {{ item.filename }}
                  </v-list-item>

                  <v-btn
                    color="red"
                    class="ml-2 mr-4"
                    @click="removeAttach(item)"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </span>
              </v-sheet>
            </v-col>
          </v-row>

          <!-- Entry Info section -->
          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.enterInfo") }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12" lg="4" md="3" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.employeeId')"
                v-model="myObj.employee_id"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="4" md="3" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.companyEmail')"
                v-model="myObj.email"
                :rules="[rule.required]"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="4" md="3" sm="6">
              <v-autocomplete
                :items="departmentsList"
                :label="$vuetify.lang.t('$vuetify.department')"
                v-model="myObj.departments[0]"
                :rules="[rule.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12" lg="4" md="3" sm="3">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.position')"
                v-model="myObj.position"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="4" md="3" sm="3">
              <v-menu
                v-model="date2"
                :close-on-content-click="true"
                transition="scale-transition"
                min-width="auto"
                class="ma-0 pa-0"
              >
                <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                  <v-text-field
                    append-icon="mdi-calendar"
                    v-model="myObj.entry_time"
                    :label="$vuetify.lang.t('$vuetify.enterTime')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="myObj.entry_time"
                  @input="date2 = false"
                  class="ma-0 pa-0"
                />
              </v-menu>
            </v-col>

            <v-col class="py-0" cols="12" lg="4" md="3" sm="3">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.probationSalary')"
                v-model="myObj.probation_salary"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-select
                :items="companiesList"
                :label="$vuetify.lang.t('$vuetify.sstc')"
                item-text="company"
                item-value="id"
                v-model="myObj.sell_companys[0]"
                disabled
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="4" md="3" sm="3">
              <v-menu
                v-model="date3"
                :close-on-content-click="true"
                transition="scale-transition"
                min-width="auto"
                class="ma-0 pa-0"
              >
                <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                  <v-text-field
                    append-icon="mdi-calendar"
                    v-model="myObj.regular_time"
                    :label="$vuetify.lang.t('$vuetify.timeBecomeRegular')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="myObj.regular_time"
                  @input="date3 = false"
                  class="ma-0 pa-0"
                />
              </v-menu>
            </v-col>

            <v-col class="py-0" cols="12" lg="5" md="3" sm="3">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.timeBecomeReqular')"
                v-model="myObj.regular_salary"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.duties')"
                v-model="myObj.duties"
                outlined
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.note')"
                v-model="myObj.entry_note"
                outlined
              />
            </v-col>
          </v-row>

          <!-- Contact Detail Section -->
          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.contactDetails") }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12" lg="3" md="3" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.personalEmail')"
                v-model="myObj.person_email"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="3" md="3" sm="4">
              <v-text-field label="Facebook" v-model="myObj.facebook" />
            </v-col>

            <v-col class="py-0" cols="12" lg="3" md="3" sm="4">
              <v-text-field label="Telegram" v-model="myObj.telegram" />
            </v-col>

            <v-col class="py-0" cols="12" lg="3" md="3" sm="12">
              <v-text-field label="WeChat" v-model="myObj.wechat" />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12" lg="3" md="3" sm="3">
              <v-text-field label="Line" v-model="myObj.line" />
            </v-col>

            <v-col class="py-0" cols="12" lg="3" md="3" sm="3">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.emergencyPerson')"
                v-model="myObj.emergency_contact"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="3" md="3" sm="3">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.emergencyPhone')"
                v-model="myObj.emergency_phone"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="3" md="3" sm="3">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.yujjlxrgx')"
                v-model="myObj.emergency_relationship"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-0" cols="12" lg="12">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.note')"
                :no-resize="true"
                outlined
              />
            </v-col>
          </v-row>

          <!-- Auth Section -->
          <v-row
            v-if="
              permissionCheck('modify', 'user_account') &&
              (permissionInfo.is_admin ||
                userInfo.role_level > myObj.role_level)
            "
          >
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.authorization") }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12" lg="2" md="2" sm="12">
              <v-switch
                v-model="myObj.has_account"
                :label="$vuetify.lang.t('$vuetify.isHasOpen')"
              />
            </v-col>

            <v-col
              class="py-0"
              cols="12"
              lg="10"
              md="10"
              sm="12"
              v-if="myObj.has_account"
            >
              <v-row>
                <v-col cols="12" lg="3" md="3" sm="6">
                  <v-select
                    :items="filteredRoleList"
                    :label="$vuetify.lang.t('$vuetify.roles')"
                    item-text="name"
                    item-value="id"
                    v-model="myObj.role_id"
                    :rules="[rule.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="6"
                  v-if="permissionInfo.is_admin"
                >
                  <v-select
                    v-model="myObj.relation_people"
                    :label="$vuetify.lang.t('$vuetify.associate')"
                    :items="userList"
                    item-text="name"
                    return-object
                    hide-selected
                    multiple
                    clearable
                    small-chips
                  />
                </v-col>

                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="6"
                  v-if="permissionInfo.is_admin"
                >
                  <v-switch
                    v-model="myObj.is_admin"
                    :label="$vuetify.lang.t('$vuetify.administrator')"
                  />
                </v-col>

                <v-col
                  class="d-flex align-center"
                  cols="12"
                  lg="3"
                  md="3"
                  sm="6"
                  v-if="myObj.id != ''"
                >
                  <v-checkbox v-model="resetPassCheckBox" />

                  <label v-if="!resetPassCheckBox">
                    {{ $vuetify.lang.t("$vuetify.resetPassword") }}?
                  </label>

                  <v-text-field
                    v-else
                    label="Password"
                    :rules="[rule.required, rule.passMin]"
                    v-model="loginAndPass.pass"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />

      <v-btn color="red darken-3" outlined @click="cancelForm">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>

      <v-btn color="green darken-3" @click="validate">
        {{ $vuetify.lang.t("$vuetify.save") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
import { profilePicUpload, userPasswordReset } from "@/api/company";
import { roleList } from "@/api/system";
import { mapGetters } from "vuex";
import serverConfig from "@/utils/serverConfig";
import { draggable } from "@/api/customFunction";

function FilterDepartment(mainDept, departments, list) {
  if (departments != undefined) {
    for (let i = 0; i < departments.length; i++) {
      list.push({
        text: mainDept + departments[i].name,
        value: departments[i].code,
      });
      if (departments[i].items != null) {
        FilterDepartment(
          mainDept + departments[i].name + " / ",
          departments[i].items,
          list
        );
      }
    }
  }
  return list;
}

export default {
  name: "UserCRUD",
  props: {
    dialog: Boolean,
    myObj: Object,
    departments: Array,
    userList: Array,
    companiesList: Array,
  },
  components: {
    ImageUpload: () => import("@/components/imageUpload"),
    DialogTitle: () => import("@/components/dialogTitle"),
    // custom-dialog: () => import("@/components/custom-dialog"),
  },
  data() {
    return {
      blankProfile: serverConfig.blank_profile_img,
      dataUrl: serverConfig.img_url,
      resetPassCheckBox: false,
      uploadedImg: undefined,
      imgUploaded: false,
      valid: false,
      birthday: null,
      date2: new Date().toISOString().substr(0, 10),
      date3: new Date().toISOString().substr(0, 10),
      files: undefined,
      loginAndPass: {
        user_id: "",
        pass: "",
      },
      rule: {
        required: (v) => !!v || "this field is required",
        passMin: (v) =>
          (!!v && v.length > 5) || "Password length must be at least 6 digits",
      },
      roleList: [],
    };
  },
  methods: {
    async validate() {
      const arr = [];
      var temp = [];

      if (this.$refs.form.validate()) {
        if (this.uploadedImg != undefined) {
          await this.uploadFile(this.uploadedImg)
            .then((data) => {
              this.myObj.avatar = data;
            })
            .catch((err) => {
              console.log(err);
            });
        }

        if (this.myObj.has_account && this.resetPassCheckBox) {
          console.log("password");

          this.loginAndPass.user_id = this.myObj.id;

          await userPasswordReset(this.loginAndPass)
            .then((logInRes) => {
              console.log(logInRes);
              this.resetPassCheckBox = false;
            })
            .catch((err) => {
              console.log("Password Reset Error", err);
              this.$toast.info("Passowrd Reset Error", { timeout: 1500 });
            });
        }

        if (this.files != undefined) {
          if (this.myObj.attach.length > 0)
            this.myObj.attach.forEach((p) => temp.push(p.md5));

          this.files.forEach((p) => {
            arr.push(this.uploadFile(p));
          });

          await Promise.all(arr)
            .then((res) => {
              res.forEach((p) => temp.push(p));
            })
            .catch((err) => {
              console.log("Upload Attchments Error", err);
            });
        }

        this.myObj.attach = JSON.stringify(temp);

        this.$emit("handleData", this.myObj);

        this.files = [];
      }
    },
    async uploadFile(file) {
      var arr = "";

      const fileForm = new FormData();
      fileForm.append("file", file);

      await profilePicUpload(fileForm)
        .then((res) => {
          arr = res.data.md5;
        })
        .catch((err) => console.log("Finanical File Error", err));

      return arr;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    cancelForm() {
      this.resetValidation();
      this.uploadedImg = undefined;
      this.$emit("update:dialog", false);
    },
    saveBd(date) {
      this.$refs.birthday.save(date);
    },
    getUsersList() {
      roleList()
        .then((res) => {
          if (res.meta === 0 && res.items !== null) {
            this.roleList = res.items;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeAttach(item) {
      this.myObj.attach.splice(this.myObj.attach.indexOf(item), 1);
    },
    drag() {
      draggable();
    },
  },
  mounted() {
    this.getUsersList();
  },
  computed: {
    ...mapGetters(["permissionInfo", "userInfo"]),

    departmentsList: function () {
      return FilterDepartment("", this.departments, []);
    },
    filteredRoleList: function () {
      return this.permissionInfo.is_root || this.permissionInfo.is_admin
        ? this.roleList
        : this.roleList.filter((r) => this.userInfo.role_level > r.level);
    },
  },
  watch: {
    birthday(val) {
      val && setTimeout(() => (this.$refs.BdPicker.activePicker = "YEAR"));
    },
    imgUploaded() {
      if (this.myObj.has_account && this.resetPassCheckBox) {
        this.loginAndPass.user_id = this.myObj.id;
        userPasswordReset(this.loginAndPass)
          .then((logInRes) => {
            this.resetPassCheckBox = false;
          })
          .catch(alert);
      }
      this.$emit("handleData", this.myObj, this.files);
      this.files = undefined;
    },
  },
};
</script>